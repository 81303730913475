<template>
  <div class="home">
    <Header></Header>
     <div class="main-box">
			<div class="product01-box">
				<img v-if="bannerdata.length>0" :src="bannerdata[0].coverPicture" alt="">
			</div>
			<div class="product02-box">
				<div class="product02-content">
					<div class="select-box">
						<div class="left-img">
							<img src="../assets/img/select-btn.png" alt="">
						</div>
						<div class="right-input">
							<input type="text" v-model="searchText" @keyup.enter="selectFn" placeholder="搜索" class="input">
						</div>
					</div>
					<div class="content-box" v-if="newMsg.length>0">
						<div class="product02-slide" v-for="(item,index) in newMsg" :key="index" @click="goDetails(item.id)">
							<div class="top-time">
								<div class="top-time-logo">
									<img src="../assets/img/time-logo.png" alt="">
								</div>
								<p class="text Gotham-Book">{{timestampToTime(item.metadataInfo.CUSTOMER_PORTAL_PUBLISH_TIME)}}</p>
							</div>
							<div class="top-img">
								<img :src="item.coverPicture" alt="">
							</div>
							<div class="footer-text">
								<p class="txt-p Gotham-Book lantingheijian">{{item.name}}</p>
							</div>
						</div>
					</div>
        <div class="content-box content-box2" v-else-if="newMsg.length<1&&!newMsgStatus">
          <img class="no-msg2" src="../assets/img/loading.png" alt="">
        </div>
        <div class="content-box content-box2" v-else>
          <img class="no-msg2" src="../assets/img/zanwushuju.png" alt="">
        </div>
					
				</div>
				
			</div>
			<div :class="[updateRecords.records&&updateRecords.records.length<5?'product03-box product03-box-min':'product03-box']">
				<div class="top-tit">
					<p class="p1 lantinghei">更新信息</p>
					<p class="p2 Gotham-Book lantingheijian">了解ZUI往期更新记录</p>
				</div>
				<div class="nav-table">
					<div :class="[index==undateIndex?'nav-slide nav-slide-active':'nav-slide']" v-for="(item,index) in updateProductNavList" :key="index" >
						<div class="top-hover-box" @click="navSlide(index,item)">
							<div class="top-img">
								<img v-if="item.name=='YOGA'||item.value=='20220816000004'" class="img1" style="width:.82rem;" src="../assets/img/product03-logo1.png" alt="">
								<img v-if="item.name=='YOGA'||item.value=='20220816000004'" class="img2" style="width:.82rem;" src="../assets/img/product03-logo1-2.png" alt="">
                <img v-if="item.name=='小新'||item.value=='20220816000006'" class="img1" style="width:.82rem;" src="../assets/img/product03-logo2.png" alt="">
								<img v-if="item.name=='小新'||item.value=='20220816000006'" class="img2" style="width:.82rem;" src="../assets/img/product03-logo2-2.png" alt="">
                <img v-if="item.name=='拯救者'||item.value=='20220816000005'" class="img1" style="width:.82rem;" src="../assets/img/product03-logo3.png" alt="">
								<img v-if="item.name=='拯救者'||item.value=='20220816000005'" class="img2" style="width:.82rem;" src="../assets/img/product03-logo3-2.png" alt="">
                <!-- <img class="img1" style="width:.82rem;" src="../assets/img/product03-logo4.png" alt="">
								<img class="img2" style="width:.82rem;" src="../assets/img/product03-logo4-2.png" alt=""> -->
							</div>
							<p class="top-txt lantinghei Gotham-Book">{{item.name}}</p>
							<div class="bottom-line"></div>
							<div class="bottom-line-active"></div>
						</div>
						<div class="two-nav-box">
							<div :class="[twoUndateIndex==0?'two-slide two-slide-active Gotham-Book lantingheijian':'two-slide Gotham-Book lantingheijian']" @click="navSlide(index,item)">全部</div>
							<div :class="[twoUndateIndex==index2+1?'two-slide two-slide-active Gotham-Book lantingheijian':'two-slide Gotham-Book lantingheijian']" class="two-slide" v-for="(item2,index2) in item.childList" @click.stop="twoNavSlide(index2,item2)" :key="index2">{{item2.name}}</div>
						</div>
					
					</div>

				</div>
        	<div class="three-nav-box">
							<div class="three-nav-rel" v-if="updateRecords.records&&updateRecords.records.length>0">
								<div class="product03-slide" v-for="(item,index) in updateRecords.records" :key="index" @click.stop="goYearsUpdate(item.metadataInfo.CUSTOMER_PORTAL_PRODUCT_NUMBER[0].value)">
									<div class="top-img">
										<img :src="item.coverPicture" alt="">
									</div>
									<div class="footer-text">
										<p class="txt-p Gotham-Book lantingheijian">{{item.metadataInfo.CUSTOMER_PORTAL_PRODUCT_NUMBER[0].title}}</p>
									</div>
								</div>
								<div class="bottom-btn" v-if="updateRecords.totalPage>1">
									<div class="bottom-btn-rel">
										<div class="prov-btn">
											<img class="img1" v-show="updatePageNum==1"  src="../assets/img/product03-btn1.png" alt="">
											<img class="img2" v-show="updatePageNum>1" @click="provFn" src="../assets/img/product03-btn1-2.png" alt="">
										</div>
										<div class="prov-btn">
											<img class="img1" v-show="updatePageNum==updateRecords.totalPage" src="../assets/img/product03-btn2.png" alt="">
											<img class="img2" @click="nextFn(updateRecords.totalPage)" v-show="updatePageNum<updateRecords.totalPage&&updateRecords.totalPage>1" src="../assets/img/product03-btn2-2.png" alt="">
										</div>
									</div>
								</div>
							</div>
              <div class="three-nav-rel three-nav-rel2" v-else-if="(updateRecords.records&&updateRecords.records.length)<1&&!updateRecordsStatus">
							    <p class="no-msg-txt Gotham-Book lantingheijian">
                    	<img class="no-msg" src="../assets/img/loading.png" alt="">
                  </p>
							</div>
							<div class="three-nav-rel three-nav-rel2" v-else>
							    <p class="no-msg-txt Gotham-Book lantingheijian">
                    	<img class="no-msg" src="../assets/img/zanwushuju.png" alt="">
                  </p>
							</div>
						</div>
			</div>
			<div class="product04-box">
				<img v-if="updatedata.length>0" :src="updatedata[0].coverPicture" alt="">
			</div>
			<div class="product05-box">
				<p class="big-p lantinghei">目前正在内测机型</p>
				<div class="product05-content" v-if="testModel.length>0">
					<div class="product05-slide" v-for="(item,index) in testModel" :key="index">
						<div class="top-img">
							<img :src="item.coverPicture" alt="">
						</div>
						<div class="footer-text">
							<p class="txt-p Gotham-Book lantingheijian">{{item.metadataInfo.CUSTOMER_PORTAL_PRODUCT_NUMBER[0].title}}</p>
						</div>
					</div>
				</div>
        <div class="product05-content product05-content2" v-else-if="testModel.length<1&&!testModelStatus">
          	<img class="no-msg2" src="../assets/img/zanwushuju.png" alt="">
        </div>
        <div class="product05-content product05-content2" v-else>
          	<img class="no-msg2" src="../assets/img/zanwushuju.png" alt="">
        </div>
			</div>
			<div class="product06-box">
				<img src="../assets/img/product06-img1.png" alt="">
        <div class="weixincode">
          <img :src="weixincode" alt="">
        </div>
        <div class="qqcode">
          <img :src="qqcode" alt="">
        </div>
			</div>
      <Footer></Footer>
		</div>
  </div>
</template>

<script>
//设置html font-size
document.documentElement.style.fontSize =
  document.documentElement.clientWidth / 38.4 + "px";
window.onresize = () => {
  document.documentElement.style.fontSize =
    document.documentElement.clientWidth / 38.4 + "px";
};
/* 
var blockIdArr={
    335 最新消息Banner
    327 最新消息>内容详情


    362 更新信息>机型图


    
    334 更新记录>内容详情（待确认）

    333 内测机型Banner

    366 内侧机型>机型列表

    363 二维码-微信

    364 二维码-微博
    indexBanner:335,
    newMsg:327,
    newsjixing:362,
    update:334,
    neicebanner:333,
    neicejixing:366,
    vxcode:363,
    wbcode:364
}
*/
//接口地址引入
import interfaces from "../utils/baseUrl";
import axios from 'axios';
import Header from "../components/Header"
import Footer from "../components/Footer"
import instance from "../utils/http";
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      //搜索信息
      searchText:'',
      //首页banner
      bannerdata:[
        {coverPicture:''}
      ],
      //更新信息当前显示
      undateIndex:0,
      //更新二级信息当前显示
      twoUndateIndex:0,
      //最新消息列表
      newMsg:[],
      newMsgStatus:false,
      //更新信息banner
      updatedata:[
        {coverPicture:''}
      ],
      //机型图
      modelImg:[],
      //更新信息
      updateRecords:[],
      updateRecordsStatus:false,
      updatePageNum:1,
      updatePageSize:8,
      weixincode:"",
      qqcode:"",
      //内测机型信息
      testModel:[],
      testModelStatus:false,
      selectArray:[],
      updateProductNavList:[],
      undateLineList:[
   
    {
        "id": "13014",
        "parent_id": "-1",
        "title": "小新",
        "value": "20220816000006"
    },
    {
        "id": "13015",
        "parent_id": "-1",
        "title": "拯救者",
        "value": "20220816000005"
    },
     {
        "id": "13013",
        "parent_id": "-1",
        "title": "YOGA",
        "value": "20220816000004"
    },
    {
        "id": "13017",
        "parent_id": "13013",
        "title": "Pad Pro 系列",
        "value": "20220816000002"
    },
    {
        "id": "13018",
        "parent_id": "13017",
        "title": "YOGA Pad Pro",
        "value": "1660533045397"
    },
    {
        "id": "13019",
        "parent_id": "13014",
        "title": "Pad 系列",
        "value": "20220816000001"
    },
    {
        "id": "13020",
        "parent_id": "13014",
        "title": "Pad Pro 系列",
        "value": "20220816000009"
    },
    {
        "id": "13021",
        "parent_id": "13014",
        "title": "Pad Plus 系列",
        "value": "20220816000003"
    },
    {
        "id": "13022",
        "parent_id": "13019",
        "title": "小新 Pad 2022",
        "value": "1660532990839"
    },
    {
        "id": "13023",
        "parent_id": "13019",
        "title": "小新 Pad 2020",
        "value": "1660532995628"
    },
    {
        "id": "13024",
        "parent_id": "13020",
        "title": "小新 Pad Pro",
        "value": "1660532999726"
    },
    {
        "id": "13025",
        "parent_id": "13020",
        "title": "小新 Pad Pro 2021",
        "value": "1660533005730"
    },
    {
        "id": "13026",
        "parent_id": "13020",
        "title": "小新 Pad Pro 12.6",
        "value": "1660533030393"
    },
    {
        "id": "13027",
        "parent_id": "13020",
        "title": "小新 Pad Pro 2022",
        "value": "1660533033319"
    },
    {
        "id": "13028",
        "parent_id": "13021",
        "title": "小新 Pad Plus",
        "value": "1660533037850"
    },
    {
        "id": "13029",
        "parent_id": "13021",
        "title": "小新 Pad Plus 5G",
        "value": "1660533041608"
    },
    {
        "id": "13030",
        "parent_id": "13015",
        "title": "电竞手机",
        "value": "20220816000008"
    },
    {
        "id": "13031",
        "parent_id": "13015",
        "title": "电竞平板",
        "value": "20220816000007"
    },
    {
        "id": "13032",
        "parent_id": "13030",
        "title": "拯救者电竞手机 Y90",
        "value": "1660532872160"
    },
    {
        "id": "13033",
        "parent_id": "13030",
        "title": "拯救者电竞手机 Y70",
        "value": "1660532970634"
    },
    {
        "id": "13034",
        "parent_id": "13030",
        "title": "拯救者电竞手机一代",
        "value": "1660532975902"
    },
    {
        "id": "13035",
        "parent_id": "13030",
        "title": "拯救者电竞手机 2Pro",
        "value": "1660532980832"
    },
    {
        "id": "13036",
        "parent_id": "13031",
        "title": "拯救者电竞平板 Y700",
        "value": "1660532986406"
    }
]
    };
  },
  beforeMount: function () {
    //获取链接参数
    var query = this.$route.query;
    //console.log(this.undateLineList[0].id)
    //设置整体展厅参数
     var getSelectArray=this.dataFilter('parent_id',this.undateLineList[0].id,this.undateLineList);
      var selectArray=[];
      selectArray.push(this.undateLineList[0].value);
      for(var i=0;i<getSelectArray.length;i++){
        selectArray.push(getSelectArray[i].value)
      }
      //console.log(getSelectArray)
      // this.cacheKey = query.cacheKey;
      this.getUpdateMsg(selectArray)
      // this.navSlide(0,this.undateLineList[0].value,this.undateLineList[0].id)
  },
  //初始化执行
  created: function () {
    let $this = this;
    // $this.getBaseInfo(335)
    //获取首页banner
    var indexBanner={
      "blockId": interfaces.blockIdArr.indexBanner,
      "pageNum":1,
      "pageSize":1,
      "searchText":"",
      "filterConditionList":[]
    }
   $this.searchFn(indexBanner)
    var newsMssdata={
      "blockId": interfaces.blockIdArr.newMsg,
      "pageNum":1,
      "pageSize":8,
      "searchText":"",
      "filterConditionList":[]
    }
    $this.searchFn(newsMssdata)
    //获取首页banner
    var updateBanner={
      "blockId": interfaces.blockIdArr.neicebanner,
      "pageNum":1,
      "pageSize":1,
      "searchText":"",
      "filterConditionList":[]
    }
   $this.searchFn(updateBanner)
    //获取首页banner
    var newsjixing={
      "blockId": interfaces.blockIdArr.newsjixing,
      "pageNum":1,
      "pageSize":20,
      "searchText":"",
      "filterConditionList":[]
    }
    $this.searchFn(newsjixing) 


    $this.searchFn({
      "blockId": interfaces.blockIdArr.update,
      "pageNum":this.updatePageNum,
      "pageSize":this.updatePageSize,
      "searchText":"",
      "filterConditionList":[]
    })


     //获取微信二维码
     $this.searchFn({
      "blockId": interfaces.blockIdArr.vxcode,
      "pageNum":1,
      "pageSize":1,
      "searchText":"",
      "filterConditionList":[]
    })
    //获取QQ二维码
     $this.searchFn({
      "blockId": interfaces.blockIdArr.wbcode,
      "pageNum":1,
      "pageSize":1,
      "searchText":"",
      "filterConditionList":[]
    })
    //获取内测机型
     $this.searchFn({
      "blockId": interfaces.blockIdArr.neicejixing,
      "pageNum":1,
      "pageSize":20,
      "searchText":"",
      "filterConditionList":[]
    })
    
    $this.getBaseInfo332(interfaces.blockIdArr.newsjixing)
  },
  //事件
  methods: {
    //获取更新信息 listhttps://open-auth.tezign.com/open-api/standard/v1/getPublishSetting
    getBaseInfo332(id){
      var $this = this;
			var data = {
				token: "",
				param: {
				blockId:id,
				},
			};
			instance
				.post(`/open-api/standard/v1/getPublishSetting`, data, {
				headers: {
					"Content-Type": "application/json",
					"Token-type": "bearer",
				},
				})
				.then((res) => {
				
				if(res&&res.data.result){
					// 获取初始化数据
					console.log(res.data.result[0].childList)
          this.updateProductNavList=res.data.result[0].childList.filter(item=>{
            return item.name!="无"
          })
          console.log(this.updateProductNavList)
          this.navSlide(0,this.updateProductNavList[0])
				}else{
					
						
				}
				
				});
    },
    
    //获取导航
   getBaseInfo(id){
        var $this = this;
        var config = {
          method: "get",
          url: `${interfaces.baseUrl}/portal/open/block/public/zui/getBaseInfo/${id}`,
          headers:interfaces.headers(),
        };
  
       axios(config)
          .then(function (response) {
            return response.data
          })
          .catch(function (error) {
            return error
          });
    },
     //搜索内容区块中内容
    searchFn(data){
        var $this = this;
        var config = {
            method: "post",
            url: `${interfaces.baseUrl}/portal/open/block/search`,
            headers:interfaces.postHeaders(),
            data,
            };
    
           axios(config)
            .then(function (response) {
                if(data.blockId==interfaces.blockIdArr.indexBanner){
                  $this.bannerdata=response.data.result.records;
                }else if(data.blockId==interfaces.blockIdArr.newMsg){
                  $this.newMsgStatus=true;
                  $this.newMsg=response.data.result.records;
                }else if(data.blockId==interfaces.blockIdArr.neicebanner){
                  $this.updatedata=response.data.result.records;
                }else if(data.blockId==interfaces.blockIdArr.neicejixing){
                  $this.testModelStatus=true
                  $this.testModel=response.data.result.records;
                }else if(data.blockId==interfaces.blockIdArr.wbcode){
                  if(response.data.result.records!=null&&response.data.result.records!=undefined&&response.data.result.records!=""){
                    if(response.data.result.records[0].coverPicture){
                      $this.qqcode=response.data.result.records[0].coverPicture;
                    }
                  }
                  //console.log($this.qqcode)
                }else if(data.blockId==interfaces.blockIdArr.vxcode){
                  if(response.data.result.records!=null&&response.data.result.records!=undefined&&response.data.result.records!=""){
                    
                    if(response.data.result.records[0].coverPicture){
                      $this.weixincode=response.data.result.records[0].coverPicture;
                    }
                  }
                }
            })
            .catch(function (error) {
                return error
            });
      },
        //获取内容详情
        getContent(data){
          var $this = this;
          //console.log(this)
          var config = {
              method: "post",
              url: `${interfaces.baseUrl}/portal/open/block/getContent`,
              headers:interfaces.postHeaders(),
              data,
            };
            axios(config)
              .then(function (response) {
                  ////console.log(response.data);
                  return response
              })
              .catch(function (error) {
                  return error
              });
        },
        timestampToTime(timestamp) {
            var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
            var D = date.getDate() + ' ';
            return Y+M+D;
        },
        goDetails(id){
          //console.log(id);
          this.$router.push({
            name: 'newsdetails',
            query:{
              id:id
            }
          })
        },
        selectFn(){
          this.$router.push({
            name: 'newsSelect',
            query:{
              searchText:this.searchText
            }
          })
        },
        goYearsUpdate(value){
          this.$router.push({
            name: 'yearsUpdate',
            query:{
              value
            }
          })
        },
        //更新数据过滤
        dataFilter(key,value,tarrget){
            return tarrget.filter((item,index)=>{
              return item[key]==value
            })
        },
        //当前所在悬浮
        navSlide(index,item){
          var $this=this
           this.undateIndex=index;
           this.twoUndateIndex=0;
           this.updatePageNum=1
           $this.selectArray=[];
           $this.selectArray.push(item.value)
           if(item.childList&&item.childList.length>0){
             for(let i=0;i<item.childList.length;i++){
              if(item.childList[i].childList&&item.childList[i].childList.length>0){
                for(let j=0;j<item.childList[i].childList.length;j++){
                  $this.selectArray.push(item.childList[i].childList[j].value)
                }
               $this.selectArray.push(item.childList[i].value)
             }
             }
           }
           console.log($this.selectArray)
           this.getUpdateMsg($this.selectArray)
        },
        //当前所在悬浮
        twoNavSlide(index,item){
          var $this=this
           this.twoUndateIndex=index+1;
           this.updatePageNum=1
           $this.selectArray=[];
           console.log(item);
           $this.selectArray.push(item.value)
           if(item.childList&&item.childList.length>0){
             for(let i=0;i<item.childList.length;i++){
               $this.selectArray.push(item.childList[i].value)
             }
           }
          //  $this.selectArray.push(value);
          //  for(let i=0;i<getSelectArray.length;i++){
          //    $this.selectArray.push(getSelectArray[i].value)
          //  }
          //  console.log($this.selectArray)
           this.getUpdateMsg($this.selectArray)
        },
        provFn(){
          var $this=this
          $this.updatePageNum=$this.updatePageNum-1
          if($this.updatePageNum<1){
            $this.updatePageNum=1
          }
          $this.getUpdateMsg($this.selectArray)
        },
         nextFn(allPageNum){
          var $this=this
          $this.updatePageNum=$this.updatePageNum+1
          if($this.updatePageNum>allPageNum){
            $this.updatePageNum=allPageNum
          }
          $this.getUpdateMsg($this.selectArray)
        },
        //获取更新信息
        getUpdateMsg(valueArray){
          var $this=this;
          $this.updateRecordsStatus=false;
          var config = {
            method: "post",
            url: `${interfaces.baseUrl}/portal/open/block/search`,
            headers:interfaces.postHeaders(),
            data:{
            "blockId": interfaces.blockIdArr.newsjixing,
            "pageNum":this.updatePageNum,
            "pageSize":this.updatePageSize,
            "searchText":"",
            "filterConditionList":[
              {
                  "type": 4,
                  "code": "CUSTOMER_PORTAL_PRODUCT_NUMBER",
                  "value": valueArray
              }
            ]
          },
            };
    
           axios(config)
            .then(function (response) {
              //console.log(response.data.result)
               $this.updateRecords=response.data.result
               //console.log($this.updateRecords)
               $this.updateRecordsStatus=true;
            })
            .catch(function (error) {
                return error
            });
        }
        
  },
};
</script>

<style lang="less" scoped>

body{
	overflow: auto;
	background:#ffffff !important;
}
.main-box{
	width: 100%;
	overflow: hidden;
	position: relative;
}
.main-box img{
	display: block;
	width: 100%;
}
.main-box .product01-box{
	width: 100%;
  height:5rem;
  margin-top:.96rem;
}
.product02-box{
	position: relative;
	width: 100%;
	overflow: hidden;
}
.product02-box .product02-content{
	width:1200px;
	margin:16.5px auto;
	overflow: hidden;
	padding-bottom:70px;
	border-bottom: 1px solid #dfe1e5;
}
.product02-box .product02-content .select-box{
   float: right;
   width:140px;
   height: 35px;
   line-height: 35px;
   position: relative;
   display: flex;
   justify-content: center;
   border: 1px solid #dfe1e5;
   border-radius: 5px;
   box-sizing: border-box;
   align-items: center;
   overflow: hidden;
}
.product02-box .product02-content .select-box input{
	background: none;
	margin-left: 7.5px;
	color: #000000;
	font-size: 12px;
}
.product02-box .product02-content .select-box input::-webkit-input-placeholder {
    color:#dfe1e5;
}
.product02-box .product02-content .select-box input::-moz-placeholder { 
    color: #dfe1e5;
}
.product02-box .product02-content .select-box input:-ms-input-placeholder {
    color: #dfe1e5;
}
.product02-box .product02-content .select-box input:-moz-placeholder {
    color: #dfe1e5;
}
.product02-box .product02-content .select-box .left-img{
	width:14px;
	height: 14px;
	display: block;
}
.product02-box .product02-content .select-box .right-input{
	width: 100px;
}
.product02-box .product02-content .content-box{
   width: 100%;
   overflow: hidden;
   display: flex;
   flex-wrap: wrap !important;
   float: left;
}
.content-box2{
  margin-top: 40px;
  background: #F5F5F5;
}
.product02-box .product02-content .content-box .product02-slide{
	position: relative;
	width:280px;
	margin-left:26px;
	margin-top:25px;
	border-radius:10px;
	overflow: hidden;
  background: #f5f5f6;
  cursor: pointer;
}
.product02-box .product02-content .content-box .product02-slide:nth-of-type(4n+1){
	margin-left: 0;
}
.product02-box .product02-content .content-box .product02-slide .top-time{
	position: absolute;
	top:1px;
	left:9px;
	/* width:2rem; */
  padding: 0 10px;
  line-height: 23px;
	height: 28px;
	border-radius:21px;
	background: #f3f3f3;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size:16px;
  color: #919191;
  z-index: 10;
}
.product02-box .product02-content .content-box .product02-slide .top-time .top-time-logo{
	width:16px;
}
.product02-box .product02-content .content-box .product02-slide .top-time .text{
	margin-left:5px;
}
.product02-box .product02-content .content-box .product02-slide .top-img{
	display: block;
  width: 100%;
  height:210px;
  overflow: hidden;
}
.product02-box .product02-content .content-box .product02-slide .top-img img{
transition: all 0.6s;
}
.product02-box .product02-content .content-box .product02-slide:hover .top-img img{
  transform: scale(1.06);
}
.product02-box .product02-content .content-box .product02-slide .footer-text{
	width: 100%;
	box-sizing: border-box;
	padding: 10px 12.5px;
	color: #5a596c;
	font-size: 15px;
	line-height: 20px;
}
/* 样式3 */
.product03-box{
	position: relative;
	width: 100%;
	overflow: hidden;
  margin-top:77px;
  padding-bottom: 60px;
}
.product03-box .top-tit{
	overflow: hidden;
}
.product03-box .top-tit .p1{
	text-align: center;
	color: #0d0c22;
	font-size: 50px;
}
.product03-box .top-tit .p2{
	text-align: center;
	color: #999999;
	font-size: 18px;
}
.product03-box .nav-table{
    width:860px;	
	margin: 0 auto;
	margin-top:35px;
	display: flex;
	justify-content: center;
}
.product03-box .nav-table .nav-slide .top-hover-box{
	width:215px;
	position: relative;
	border-bottom: 2px solid #ffffff;
	text-align: center;
	font-size:24px;
	color:#999999;
	padding-bottom:20px;
  box-sizing: border-box;
  cursor: pointer;
}
.product03-box .nav-table .nav-slide .top-hover-box .top-img{
	position: relative;
	width:100%;
	height:43px;
	text-align: center;
}
.product03-box .nav-table .nav-slide .top-hover-box .top-img img{
	display: block;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	position: absolute;
}
.product03-box .nav-table .nav-slide .top-hover-box .top-img .img1{
  display: block;
}
.product03-box .nav-table .nav-slide .top-hover-box .top-img .img2{
  display: none;
}
.product03-box .nav-table .nav-slide-active .top-hover-box .top-img .img1{
  display: none !important;
}
.product03-box .nav-table .nav-slide-active .top-hover-box .top-img .img2{
  display: block !important;
}
.product03-box .nav-table .nav-slide .top-hover-box .top-txt{
  margin-top: 7.5px;
  height: 28px;
}
.product03-box .nav-table .nav-slide-active .top-hover-box .top-txt{
  color: #0d0c22;
}
.product03-box .nav-table .nav-slide .top-hover-box .bottom-line{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 5px;
	background:#e0e0e0;
}
.product03-box .nav-table .nav-slide .top-hover-box .bottom-line-active{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 5px;
	background:#0d0c22;
	display: none;
}
.product03-box .nav-table .nav-slide-active .top-hover-box .bottom-line-active{
	display: block;
} 
.product03-box .nav-table .nav-slide .two-nav-box{
	position: absolute;
	top:280px;
	left:50%;
	transform: translate(-50%,0);
	display: none;
}
.product03-box .nav-table .nav-slide-active .two-nav-box{
	display: block;
}
.product03-box .nav-table .nav-slide .two-nav-box .two-slide{
	float: left;
	padding:0 15px;
	height:30px;
	line-height: 30px;
	border-radius:30px;
	border: 1px solid #242435;
	text-align: center;
	color: #5a596c;
	font-size:15px;
	margin-left:30px;
}
.product03-box .nav-table .nav-slide .two-nav-box .two-slide-active{
	background: #242435;
	color: #ffffff;
}
.three-nav-box{
  margin: 0 auto;
  margin-top: 120px;
  overflow: hidden;
	width:1200px;
}
.three-nav-box{
	display: block;
} 
.three-nav-box .product03-slide{
	float: left;
	width:280px;
	position: relative;
	margin-left:25px;
  height:273.5px;
     background: #F5F5F6;
      border-radius: 5px;
  overflow: hidden;
	margin-top:30px;
}
.three-nav-box .product03-slide:nth-of-type(4n+1){
	margin-left: 0;
}
.three-nav-box .product03-slide .footer-text{
	position: absolute;
	left: 0;
	width: 100%;
	text-align: center;
	color: #5a596c;
	font-size: 15px;
  bottom:0;
  height: 35px;
  line-height: 35px;
  background: #F5F5F6;
}
.three-nav-box .three-nav-rel{
	position: relative;
	width: 100%;
	// height: 805px;
}
.three-nav-box .three-nav-rel2{
  padding: 50px 0 100px 0;
  background: #F5F5F5;
}
.three-nav-box .three-nav-rel .no-msg-txt{
   margin-top: 50px;
   font-size: 50px;
   text-align: center;
}
.three-nav-box .three-nav-rel .no-msg-txt .no-msg{
  width: 180px;
  margin: 0 auto;
}
.three-nav-box .three-nav-rel .bottom-btn{
  float: left;
  width: 100%;
  margin-top: 40px;
  overflow: hidden;
  text-align: center;
}
.three-nav-box .three-nav-rel .bottom-btn .bottom-btn-rel{
	position: relative;
	width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.three-nav-box .three-nav-rel .bottom-btn .bottom-btn-rel .prov-btn{
	position: relative;
	float: left;
	width:40px;
	height:40px;
}
.three-nav-box .three-nav-rel .bottom-btn .bottom-btn-rel .prov-btn:nth-of-type(2){
	margin-left:17.5px;
}
.three-nav-box .three-nav-rel .bottom-btn .bottom-btn-rel .prov-btn .img1{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
.three-nav-box .three-nav-rel .bottom-btn .bottom-btn-rel .prov-btn .img2{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
.product04-box{
	width: 100%;
	// height: 400px;
	overflow: hidden;
}
.product04-box img{
	width: 100%;
}

.product05-box{
	position: relative;
	width: 100%;
	margin-top:95px;
}
.product05-box .big-p{
	text-align: center;
	color: #0d0c22;
	font-size: 50px;
}
.product05-box .product05-content{
	width: 1200px;
	margin: 0 auto;
	overflow: hidden;
}
.product05-box .product05-content2{
  margin-top: 40px;
  background: #F5F5F5;
}
.product05-box .product05-content .product05-slide{
	float: left;
	margin:32.5px 0 0 20px;
	width:280px;
	height: 273.5px;
  position: relative;
    border-radius: 5px;
  overflow: hidden;
    background: #F5F5F6;
}
.product05-box .product05-content .product05-slide .top-img{
	width: 100%;
  height: 100%;
}
.product05-box .product05-content .product05-slide .footer-text{
	position: absolute;
	top:240px;
	left: 0;
	width: 100%;
	text-align: center;
	color: #5a596c;
  font-size: 15px;
  height: 35px;
  line-height: 35px;
  background: #F5F5F6;
}
.product06-box{
	width:1200px;
  margin: 50px auto 0 auto;
  position: relative;
  padding-bottom: 50px;
}
.product06-box .weixincode{
  width:89.5px;
  height: 89.5px;
  position: absolute;
  top:108.5px;
  left:451.5px;
}
.product06-box .qqcode{
  width:89.5px;
  height: 89.5px;
  position: absolute;
  top:108.5px;
  left:662px;
}
.no-msg2{
  width: 180px !important;
  margin: 0 auto;
  margin-top: 75px;
  padding-bottom: 75px;
}
.no-msg2 img{
  width: 100%;
}
</style>
